import { ImageWrap } from '@truphone/gatsby-wrappers'
import React from 'react'

export default function CellularModuleCard(props) {
  return (
    <div className="animation-fade w-full w-card p-4">
      <div
        className={`border-t-4 ${
          props.borderColor ? '' : 'border-blue-100'
        } bg-white p-8 pt-0 shadow-coverage rounded-t-sm rounded-b-2xl h-full`}
        style={{
          borderColor: props.borderColor
        }}
      >
        {props.image && (
          <div className="mx-auto mb-4 h-auto ">
            <ImageWrap {...props.image} />
          </div>
        )}
        {props.manufacturer && (
          <p className="text-base tracking-wider text-grey-700 font-semibold leading-tight mb-2">
            {props.manufacturer}
          </p>
        )}
        {props.modelName && <h4 className="text-base font-semibold mb-2">{props.modelName}</h4>}

        <div className="list-disc">
          <ul>
            {props.networkTags && <li>{props.networkTags}</li>}
            {props.typeTags && <li>{props.typeTags}</li>}
            {props.firmwareVersion && <li className="break-words">{props.firmwareVersion}</li>}
          </ul>
        </div>

        {props.description && (
          <div
            className="mt-8 text-xs tracking-wide leading-snug text-grey-700 break-words"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        )}
      </div>
    </div>
  )
}
