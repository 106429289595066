import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import CellularModuleCard from './CellularModuleCard'
import { Loading } from '@truphone/core-components'

export default function CellularModulesList(props) {
  const [page, setPage] = useState(1)
  const perPage = props.perPage || 8
  const [loadmore, setLoadMore] = useState(true)
  const [sortedModules, setSortedModules] = useState([])
  const [showModules, setShowModules] = useState([])

  useEffect(() => {
    setSortedModules(
      props.modules.sort(
        (a, b) =>
          a.manufacturer.localeCompare(b.manufacturer) || a.modelName.localeCompare(b.modelName)
      )
    )
  }, [props.modules])

  useEffect(() => {
    if (page === 1) {
      setLoadMore(true)
      setShowModules(sortedModules.slice(0, page * perPage))
    }
  }, [sortedModules])

  const loadFunc = (page) => {
    if (showModules.length < sortedModules.length) {
      setPage(page)
      setLoadMore(true)
      setShowModules(sortedModules.slice(0, page * perPage))
    } else {
      setLoadMore(false)
    }
  }

  return showModules && showModules.length > 0 ? (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadFunc}
      hasMore={loadmore}
      threshold={200}
      loader={<Loading key={0} />}
      useWindow={true}
    >
      <div className="flex flex-wrap -mx-4">
        {showModules.map((modules) => {
          return <CellularModuleCard key={modules.iso} {...modules} borderColor={props.cardColor} />
        })}
      </div>
    </InfiniteScroll>
  ) : (
    <div className="bg-blue-900 bg-opacity-5 max-w-sm p-4 rounded-lg text-base">
      {props.noResultsText}
    </div>
  )
}
