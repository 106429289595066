import { Select } from '@truphone/core-components'
import React, { useState, useEffect } from 'react'
import CellularModulesList from './CellularModulesList'

export default function CellularModuleContainer(props) {
  const [modules, setModules] = useState(props.modules)
  const [filter, setFilter] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [networkTags, setNetworkTags] = useState([])
  const [typeTags, setTypeTags] = useState([])

  useEffect(() => {
    setManufacturers([
      { text: props.manufacturerFilterAll, value: undefined },
      ...props.modules
        .map((x) => x.manufacturer)
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }))
    ])

    setNetworkTags([
      { text: props.networkTagsFilterAll, value: undefined },
      ...props.modules
        .map((x) => x.networkTags)
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .map((x) => x.split(', '))
        .join()
        .split(',')
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }))
    ])
    setTypeTags([
      { text: props.typeTagsFilterAll, value: undefined },
      ...props.modules
        .map((x) => x.typeTags)
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .map((x) => x.split(', '))
        .join()
        .split(',')
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }))
    ])
  }, [])

  useEffect(() => {
    let filteredModules = props.modules
    if (filter.manufacturer) {
      filteredModules = filteredModules.filter((x) => x.manufacturer === filter.manufacturer)
    }
    if (filter.networkTag) {
      filteredModules = filteredModules.filter((x) => x.networkTags.includes(filter.networkTag))
    }
    if (filter.typeTag) {
      filteredModules = filteredModules.filter((x) => x.typeTags.includes(filter.typeTag))
    }

    setModules(filteredModules)
  }, [filter])

  return (
    <div className="container mx-auto px-6 py-10">
      <div className="flex flex-col md:flex-row">
        <Select
          options={manufacturers}
          className="border my-2 md:mr-4 bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
          value={filter.manufacturer}
          onChange={(value) =>
            setFilter((prevFilter) => {
              const filter = { ...prevFilter }
              filter.manufacturer = value
              filter.networkTag = undefined
              return filter
            })
          }
        />
        <Select
          options={networkTags}
          className="border my-2 md:mr-4 bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
          value={filter.networkTag}
          onChange={(value) =>
            setFilter((prevFilter) => {
              const filter = { ...prevFilter }
              filter.networkTag = value
              return filter
            })
          }
        />
        <Select
          options={typeTags}
          className="border my-2 bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
          value={filter.typeTag}
          onChange={(value) =>
            setFilter((prevFilter) => {
              const filter = { ...prevFilter }
              filter.typeTag = value
              return filter
            })
          }
        />
      </div>

      <CellularModulesList
        modules={modules}
        cardColor={props.cardColor}
        noResultsText={props.noResultsText}
        perPage={props.perPage}
      />
    </div>
  )
}
